<template>
  <div class="d-flex min-vh-100">
    <CContainer fluid>
      <br>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                  <!-- Round<b-input v-model="round" placeholder='' class="mb-1" @input="round_update"> </b-input> {{ round_result }} <br><br><br> -->
                  Nama Box1<b-input v-model="nama_box" placeholder='' class="mb-1" @input="update2"> </b-input>
                  Ukuran Dalam<b-form-textarea v-model="a" placeholder='' rows="3" max-rows="6" class="mb-1" @input="update1"> </b-form-textarea>
                  Flute<b-input v-model="b" placeholder='' class="mb-1" @input="update2"> </b-input>
                  <!-- Substance<b-form-textarea v-model="c" placeholder='' rows="5" max-rows="6" class="mb-1" @input="update3"> </b-form-textarea> -->
                  <!-- <b-input v-model="b" placeholder='' class="mb-1" @input="update2"> </b-input>
                  <b-input v-model="b" placeholder='' class="mb-1" @input="update2"> </b-input>
                  <b-input v-model="b" placeholder='' class="mb-1" @input="update2"> </b-input>
                  <b-input v-model="b" placeholder='' class="mb-1" @input="update2"> </b-input>
                  <b-input v-model="b" placeholder='' class="mb-1" @input="update2"> </b-input> -->
                  Substance
                  <div >
                    <table class="noborder">
                      <tbody>
                        <tr>
                          <td><b-input v-model="c11" placeholder='' class="mb-1" @input="update3" type="number"> </b-input></td>
                          <td><input v-model="c12" type="radio" name="c11" value="WL" @change="update3"></td>
                          <td>WL</td>
                          <td><input v-model="c12" type="radio" name="c11" value="M" @change="update3"></td>
                          <td>M</td>
                          <td><input v-model="c12" type="radio" name="c11" value="K" @change="update3"></td>
                          <td>K</td>
                          <!-- BK change to K -->
                        </tr>
                        <tr>
                          <td><b-input v-model="c21" placeholder='' class="mb-1" @input="update3" type="number"> </b-input></td>
                          <td><input v-model="c22" type="radio" name="c21" value="WL" @change="update3"></td>
                          <td>WL</td>
                          <td><input v-model="c22" type="radio" name="c21" value="M" @change="update3"></td>
                          <td>M</td>
                          <td><input v-model="c22" type="radio" name="c21" value="K" @change="update3"></td>
                          <td>K</td>
                          <!-- BK change to K -->
                        </tr>
                        <tr>
                          <td><b-input v-model="c31" placeholder='' class="mb-1" @input="update3" type="number"> </b-input></td>
                          <td><input v-model="c32" type="radio" name="c31" value="WL" @change="update3"></td>
                          <td>WL</td>
                          <td><input v-model="c32" type="radio" name="c31" value="M" @change="update3"></td>
                          <td>M</td>
                          <td><input v-model="c132" type="radio" name="c31" value="K" @change="update3"></td>
                          <td>K</td>
                          <!-- BK change to K -->
                        </tr>
                        <tr v-if="cek">
                          <td><b-input v-model="c41" placeholder='' class="mb-1" @input="update3" type="number"> </b-input></td>
                          <td><input v-model="c42" type="radio" name="c41" value="WL" @change="update3"></td>
                          <td>WL</td>
                          <td><input v-model="c42" type="radio" name="c41" value="M" @change="update3"></td>
                          <td>M</td>
                          <td><input v-model="c42" type="radio" name="c41" value="K" @change="update3"></td>
                          <td>K</td>
                          <!-- BK change to K -->
                        </tr>
                        <tr v-if="cek">
                          <td><b-input v-model="c51" placeholder='' class="mb-1" @input="update3" type="number"> </b-input></td>
                          <td><input v-model="c52" type="radio" name="c51" value="WL" @change="update3"></td>
                          <td>WL</td>
                          <td><input v-model="c52" type="radio" name="c51" value="M" @change="update3"></td>
                          <td>M</td>
                          <td><input v-model="c52" type="radio" name="c51" value="K" @change="update3"></td>
                          <td>K</td>
                          <!-- BK change to K -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br>
                  <!-- Diskon
                  <b-input v-model="dd" placeholder='' class="mb-1" @input="update2"> </b-input> -->
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
      <br>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                  <table>
                    <tr>
                      <td style="width:150px">Nama Box:   </td>
                      <td>{{ nama_box }}</td>
                    </tr>
                    <tr>
                      <td style="width:150px">Ukuran Dalam:   </td>
                      <!-- <td>{{ a1 }} x {{ a2 }} x {{ a3 }}</td> -->
                      <td>{{ a1 }}  {{ a2 }}  {{ a3 }}</td>
                    </tr>
                    <tr>
                      <td>Flute:   </td>
                      <td>{{ b }} </td>
                    </tr>
                    <tr>
                      <td>Substance:   </td>
                      <td>{{ c1 }}  {{ c2 }}  {{ c3 }} {{ c4 }} {{ c5 }}</td>
                    </tr>
                    <tr>
                      <td>Luas:   </td>
                      <td>{{ luas }} m<sup>2</sup></td>
                    </tr>
                    <tr>
                      <td>Berat:   </td>
                      <td>{{ berat }} kg</td>
                    </tr>
                    <tr>
                      <td>Ukuran Sheet:   </td>
                      <td>{{ us1 }} x {{ us2 }} mm</td>
                    </tr>
                    <tr>
                      <td>Scoring Panjang:   </td>
                      <!-- <td>{{ sp1 }} + {{ sp2 }} + {{ sp3 }} + {{ sp4 }} + {{ sp5 }} mm = ( {{ sp6 }} ) mm</td> -->
                      <td>{{ sp1 }} + {{ sp2 }} + {{ sp3 }} + {{ sp4 }} + {{ sp3 }} mm = ( {{ sp6 }} ) mm1</td>
                    </tr>
                    <tr>
                      <td>Scoring Lebar:   </td>
                      <td>{{ sl1 }} + {{ sl2 }} + {{ sl3 }} mm = ( {{ sl4 }} ) mm</td>
                    </tr>
                  </table>
                  <br>
                  <div class="container">
                    <img src="img/lay1.jpg" alt="lay" style="width:100%;">
                  </div>
                  <br>
                  <table>
                    <tr>
                      <td style="width:80px">a : {{ xa }}</td>
                      <td style="width:80px">b : {{ xb }}</td>
                      <td style="width:80px">c : {{ xc }}</td>
                      <td style="width:80px">d : {{ xd }}</td>
                    </tr>
                    <tr>
                      <td style="width:80px">e : {{ xe }}</td>
                      <td style="width:80px">f : {{ xf }}</td>
                      <td style="width:80px">g : {{ xg }}</td>
                      <td style="width:80px">h : {{ xh }}</td>
                    </tr>
                  </table>
                  <br>
                  <!-- Harga
                  <table>
                    <tr>
                      <td style="width:100px">P : </td>
                      <td>{{ pp }}</td>
                    </tr>
                    <tr>
                      <td style="width:100px">L : </td>
                      <td>{{ ll }}</td>
                    </tr>
                  </table>                   -->


                  <p>
                    Note
                    <br>
                    Ukuran Sheet: {{ xa }} x {{ xh }}
                    <br>
                    Creasing: {{ xb }} - {{ xc }} - {{ xb }}
                  </p>


                <span class="btn btn-info text-white copy-btn ml-auto" @click.stop.prevent="copyTestingCode">Copy</span>
                <textarea id="testing-code" :value="note_text" style="display:none;"></textarea>

              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
      <br>
      <!-- <pre>{{ $data | json }}</pre> -->
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      nama_box:'OB A',
      ud1:0,
      ud2:0,
      ud3:0,
      s1:0,
      s2:0,
      s3:0,
      s4:0,
      s5:0,
      us1:0,
      us2:0,
      luas:0,
      berat:0,
      sp1:0,
      sp2:0,
      sp3:0,
      sp4:0,
      sp5:0,
      sp6:0,
      sl1:0,
      sl2:0,
      sl3:0,
      sl4:0,
      x:'',
      y:'',
      f:'',
      a:'',
      a1:'',
      a2:'',
      a3:'',
      b:'',
      c:'',
      c1:'',
      c11:'',
      c12:'WL',
      c2:'',
      c21:'',
      c22:'WL',
      c3:'',
      c31:'',
      c32:'WL',
      c4:'',
      c41:'',
      c42:'WL',
      c5:'',
      c51:'',
      c52:'WL',
      string_a:[],
      string_c:[],
      xa:0,
      xb:0,
      xc:0,
      xd:0,
      xe:0,
      xf:0,
      xg:0,
      xh:0,
      // xi:0,
      // xj:0,
      round:'',
      round_result:0,
      pp:0,
      ll:0,
      ii:0,
      dd:'',
      cek:false,
      note_text:'',
    }
  },
  mounted() {
    this.cal()
  },
  methods: {
    round_update(){

      // var precision = Math.pow(10, 5)
      // //this.round_result = 'asd';
      // this.round_result =  Math.ceil(this.round * precision) / precision

      this.round_result = Math.ceil((this.round/10)/5)*5;


    },
    update1(){
      this.string_a = this.a.split('\n')
      try{
        this.a1 = this.string_a[0] + ' x';
        this.a2 = this.string_a[1] ? this.string_a[1] + ' x' : '';
        this.a3 = this.string_a[2];

        this.ud1 = parseInt(this.a1);
        this.ud2 = parseInt(this.a2);
        this.ud3 = parseInt(this.a3);
      }catch(er){

      }
      this.cal();
    },
    update2(){
      this.b = this.b.toUpperCase();
      if (this.b == 'BC' || this.b == 'EB'){this.cek = true}else{this.cek = false}
      this.cal();
    },
    update3(){





      // this.c = this.c.toUpperCase();
      // this.string_c = this.c.split('\n')
      // try{
      //   this.c1 = this.string_c[0] + ' /';
      //   this.c2 = this.string_c[1] ? this.string_c[1] + ' /' : '';
      //   this.c3 = this.string_c[2] ? this.string_c[2] + ' /' : '';
      //   this.c4 = this.string_c[3] ? this.string_c[3] + ' /' : '';
      //   this.c5 = this.string_c[4];

      //   this.s1 = parseInt(this.c1.split(' ')[0]);
      //   this.s2 = parseInt(this.c2.split(' ')[0]);
      //   this.s3 = parseInt(this.c3.split(' ')[0]);
      //   this.s4 = parseInt(this.c4.split(' ')[0]);
      //   this.s5 = parseInt(this.c5.split(' ')[0]);
      // }catch(er){

      // }
      this.cal();
    },
    cal(){
      //input subtance
      if(this.b == 'BC' || this.b == 'EB'){
        this.c1 = this.c11 + ' ' + this.c12 + ' /';
        this.c2 = this.c21 + ' ' + this.c22 + ' /';
        this.c3 = this.c31 + ' ' + this.c32 + ' /';
        this.c4 = this.c41 + ' ' + this.c42 + ' /';
        this.c5 = this.c51 + ' ' + this.c52;
      }else{
        this.c1 = this.c11 + ' ' + this.c12 + ' /';
        this.c2 = this.c21 + ' ' + this.c22 + ' /';
        this.c3 = this.c31 + ' ' + this.c32;
        this.c4 = '';
        this.c5 = '';
      }
        this.s1 = parseInt(this.c11);
        this.s2 = parseInt(this.c21);
        this.s3 = parseInt(this.c31);
        this.s4 = parseInt(this.c41);
        this.s5 = parseInt(this.c51);


      //scoring lebar
      this.sl1 = Math.floor(this.ud2 / 2)
      if(this.b == 'B')                       {this.sl1 = this.sl1 + 2}
      else if(this.b == 'C' || this.b == 'EB'){this.sl1 = this.sl1 + 3}
      else if(this.b == 'BC')                 {this.sl1 = this.sl1 + 5}
      else                                    {this.sl1 = this.sl1 + 1}

      this.sl2 = this.ud3;
      if(this.b == 'B')                       {this.sl2 = this.sl2 + 6}
      else if(this.b == 'C' || this.b == 'EB'){this.sl2 = this.sl2 + 8}
      else if(this.b == 'BC')                 {this.sl2 = this.sl2 + 14}
      else                                    {this.sl2 = this.sl2 + 4}

      this.sl3 = Math.floor(this.ud2 / 2)
      if(this.b == 'B')                       {this.sl3 = this.sl3 + 2}
      else if(this.b == 'C' || this.b == 'EB'){this.sl3 = this.sl3 + 3}
      else if(this.b == 'BC')                 {this.sl3 = this.sl3 + 5}
      else                                    {this.sl3 = this.sl3 + 1}

      this.sl4 = this.sl1 + this.sl2 + this.sl3;


      //scoring panjang
      if(this.b == 'BC'){this.sp1 = 35}
      else              {this.sp1 = 30}

      this.sp2 = this.ud1
      if(this.b == 'B')                       {this.sp2 = this.sp2 + 3}
      else if(this.b == 'C' || this.b == 'EB'){this.sp2 = this.sp2 + 4}
      else if(this.b == 'BC')                 {this.sp2 = this.sp2 + 6}
      else                                    {this.sp2 = this.sp2 + 2}

      this.sp3 = this.ud2
      if(this.b == 'B')                       {this.sp3 = this.sp3 + 3}
      else if(this.b == 'C' || this.b == 'EB'){this.sp3 = this.sp3 + 4}
      else if(this.b == 'BC')                 {this.sp3 = this.sp3 + 6}
      else                                    {this.sp3 = this.sp3 + 2}

      this.sp4 = this.ud1
      if(this.b == 'B')                       {this.sp4 = this.sp4 + 3}
      else if(this.b == 'C' || this.b == 'EB'){this.sp4 = this.sp4 + 4}
      else if(this.b == 'BC')                 {this.sp4 = this.sp4 + 6}
      else                                    {this.sp4 = this.sp4 + 2}

      this.sp5 = this.ud2
      if(this.b == 'B')                       {this.sp5 = this.sp5 + 1}
      else if(this.b == 'C' || this.b == 'EB'){this.sp5 = this.sp5 + 2}
      else if(this.b == 'BC')                 {this.sp5 = this.sp5 + 2}
      else                                    {this.sp5 = this.sp5 + 1}

      this.sp6 = this.sp1 + this.sp2 + this.sp3 + this.sp4 + this.sp5;


      //ukuran sheet
      this.us1 = this.sl4;
      this.us2 = this.sp6;

      this.luas = (this.sp6 * this.sl4) / 1000000;



      //berat
      if(this.b == 'B')          {this.berat = ((this.s1 + (this.s2 * 1.36) + this.s3) * this.luas) / 1000 }
      else if(this.b == 'C')     {this.berat = ((this.s1 + (this.s2 * 1.43) + this.s3) * this.luas) / 1000 }
      else if(this.b == 'BC')    {this.berat = ((this.s1 + (this.s2 * 1.36) + this.s3 + (this.s4 * 1.43) + this.s5) * this.luas) / 1000 }
      else if(this.b == 'E')     {this.berat = ((this.s1 + (this.s2 * 1.21) + this.s3) * this.luas) / 1000 }
      else if(this.b == 'EB')    {this.berat = ((this.s1 + (this.s2 * 1.21) + this.s3 + (this.s4 * 1.36) + this.s5) * this.luas) / 1000 }
      else                       {this.berat = 0}

      this.luas = this.luas.toFixed(3);
      this.berat = this.berat.toFixed(3);

      this.xb = this.sl1;
      this.xc = this.sl2;
      this.xd = this.sp1;
      this.xe = this.sp2;
      this.xf = this.sp3;
      this.xg = this.xf;
      // this.xg = this.sp5;

      this.xa = this.xb + this.xc + this.xb;
      this.xh = this.xd + this.xe + this.xf + this.xe + this.xg;


      //this.round_result = Math.ceil((this.round/10)/5)*5;
      this.pp = Math.ceil((this.xh/10)/5)*5;
      this.ll = Math.ceil((this.xa/10)/5)*5;


      this.note_text =
      `Note \n` +
      `Ukuran Sheet: ` + this.xa + ` x ` + this.xh + `\n` +
      `Creasing: ` + this.xb + ` - ` + this.xc + ` - ` + this.xb

    },
    copyTestingCode () {
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('style', '')    // 不是 hidden 才能複製
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        alert('Copy to clipboard');
      } catch (err) {
        alert('Oops, unable to copy');
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('style', 'display:none;')
      window.getSelection().removeAllRanges()
    },
  }
}
</script>
<style>
table, th, td {
  border: 1px solid black;
}
.noborder, .noborder tr, .noborder th, .noborder td { border: none; }
</style>